import React, { useState, useEffect, useRef } from 'react'
import { Layout, List, Divider, Input, Badge, Modal } from 'antd'
import { SendOutlined } from '@ant-design/icons'

import InfiniteScroll from 'react-infinite-scroll-component'

import PubSub from 'pubsub-js'
import { toast } from 'react-toastify'

import Video from './Video'

import Utils from '../../../components/Utils'
import Button from '../../../components/Button'

import './index.css'

import translations from './translations'
import { LangContext } from '../../../components/Translation'
import Backend from '../../../api/Backend'

const { TextArea } = Input
const { Sider } = Layout

const types = {
	MESSAGE: 'm.room.message',
	CALL_ANSWER: 'm.call.answer',
	CALL_INVITE: 'm.call.invite',
	CALL_HANGUP: 'm.call.hangup',
	TEXT: 'm.text'
}

export default function MatrixChat({ client, display, userId, onUnreadChatMessages }) {
	const backend = new Backend()

	const [loading, setLoading] = useState(false)
	const [openRoom, setOpenRoom] = useState()
	const [rooms, setRooms] = useState([])
	const [refreshRuns, setRefreshRuns] = useState(false)
	const [newMessageEvent, setNewMessageEvent] = useState()
	const [showVideo, setShowVideo] = useState(false)

	const isAdminUserId = (userId) => {
		return userId.includes('@admin:')
	}

	const getPresence = (userId) => {
		return new Promise((resolve) => {
			backend.matrixPresence({ body: JSON.stringify({ matrixIds: [userId] }), cb: resolve })
		})
	}

	const resolveRoomName = async (room) => {
		let aliases = null
		let alias = '-'
		try {
			aliases = await client.unstableGetLocalAliases(room.roomId)
			alias = aliases?.aliases.length > 0 ? aliases.aliases[0] : null
		} catch (ex) {
			console.error(ex)
		}

		let names = []
		if (alias) {
			const parts = alias.split('_&&_')
			const domain = client.getDomain()
			const matrixIds = parts.map((part) => part.replace('#', '').replace(':', '').replace(domain, '')).map((part) => `${part}:${domain}`)
			names = await resolveMatrixId({ matrixIds })
			names = names.map((entry) => entry.userName)
		}
		return names.join(' - ')
	}

	const joinedRoom = (room) => {
		return room?.selfMembership == 'join'
	}

	const refresh = async (silent) => {
		if (!refreshRuns) {
			if (!silent) {
				setLoading(true)
				setRefreshRuns(true)
			}
			const rooms = await client.getRooms()
			const data = []
			for (let room of rooms) {
				if (joinedRoom(room)) {
					let members = room.getMembers()
					members = members
						.filter((m) => m.membership !== 'leave')
						.filter((m) => !isAdminUserId(m.userId))
						.map((m) => m.userId)

					const res = await resolveMatrixId({ matrixIds: members })
					let membersDetailed = []
					for (let d of res) {
						membersDetailed.push({
							userName: d.userName,
							userId: d.userId,
							isPatient: d.isPatient,
							presence: await getPresence(d.userId)
						})
					}
					membersDetailed = membersDetailed.sort((a, b) => Number(b.isPatient) - Number(a.isPatient))

					const roomName = await resolveRoomName(room)

					data.push({
						_d: room,
						unreadMessages: room.getUnreadNotificationCount(),
						roomId: room.roomId,
						name: roomName,
						members: membersDetailed,
						joined: room.selfMembership === 'join',
						invited: room.selfMembership === 'invite'
					})
				}
			}

			setRooms(data.sort((a, b) => a.name > b.name))
			calcUnreadMessages()
			if (!silent) {
				setRefreshRuns(false)
				setLoading(false)
			}
		}
	}

	useEffect(() => {
		PubSub.subscribe('chat:start', (e, data) => {
			addRoom(data)
		})
	}, [])

	const calcUnreadMessages = async () => {
		let total = 0
		const rooms = await client.getRooms()
		for (let room of rooms) {
			total = joinedRoom(room) ? total + room.getUnreadNotificationCount() : 0
		}
		onUnreadChatMessages(total)
	}

	useEffect(() => {
		if (client) {
			client.once('sync', (state, prevState, res) => {
				if (state === 'PREPARED') {
					refresh()
				}
				client.on('RoomMember.membership', function (event, member) {
					if (member.membership === 'invite' && member.userId === userId) {
						client.joinRoom(member.roomId).then(function () {
							console.log('Auto-joined %s', member.roomId)
						})
					}
				})
				client.on('Room.timeline', function (event, _room, toStartOfTimeline) {
					refresh(true)
					setNewMessageEvent(event)
				})
				client.on('Room', function (room) {
					refresh(true)
				})
				client.on('deleteRoom', function (event, member) {
					refresh(true)
				})
				client.on('User.presence', function (event, user) {
					var presence = user.presence
				})
			})
		}
	}, [client])

	const addRoom = async ({ patientId, patientName, cb = () => {} }) => {
		setLoading(true)
		backend
			.matrixRoom({
				patientId,
				cb: (room) => {
					room._d = client.getRoom(room.roomId) // important for internal room renderung
					displayRoom(room)
					cb(room.roomId)
					setLoading(false)
				},
				cbError: ({ message }) => {
					toast.error(message, { toastId: message })
					setLoading(false)
				}
			})
			.then(() => {})
	}

	const resolveMatrixId = ({ matrixIds }) => {
		return new Promise((resolve) => {
			backend.matrixResolveUser({ body: JSON.stringify({ matrixIds }), cb: resolve })
		})
	}

	const joinRoom = ({ room }) => {
		if (!room.joined) {
			client.joinRoom(room.roomId || room.room_id, { syncRoom: true }).then(refresh)
		}
	}

	const leaveRoom = (room) => {
		setLoading(true)
		client.leaveRoomChain(room.roomId).then(refresh)
		client.forget(room.roomId, false) // dont remove room
		hideRoom()
	}

	const displayRoom = (room) => {
		joinRoom({ room })
		setOpenRoom(room)
		refresh()
	}

	const hideRoom = () => {
		setOpenRoom(undefined)
	}

	if (!display && openRoom) {
		hideRoom()
	}

	const renderRoomActions = (item) => {
		const actions = [
			<Badge key="roomActions" count={item.unreadMessages} color="green" offset={[8, -6]}>
				<Button
					type="leave"
					onClick={() => {
						leaveRoom(item._d)
					}}
				/>
			</Badge>
		]
		return [actions]
	}
	const renderRooms = () => {
		return (
			<List
				loading={loading}
				itemLayout="horizontal"
				header={
					<div>
						<Button type="refresh" onClick={refresh} />
						{
							// <Button type="add" disabled onClick={() => setModalVisibleAddRoom(true)}/>
						}{' '}
					</div>
				}
				className="exo-matrix-chat-rooms-list"
				dataSource={rooms.filter((room) => {
					return room.invited || room.joined // filter out already left rooms yet in matrix client store
				})}
				renderItem={(item) => (
					<List.Item
						key={item.name}
						onClick={() => displayRoom(item)}
						actions={renderRoomActions(item)}
						className={openRoom && openRoom.roomId === item.roomId ? 'exo-matrix-chat-rooms-list-open' : ''}>
						<List.Item.Meta
							//avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
							title={item.name}
							key={item.roomId}
							description={
								<div>
									{item.members.map((member) => {
										const color = 'cyan'
										return (
											<Badge
												className={member.isPatient ? 'exo-matrix-chat-rooms-list-patient' : ''}
												key={member.userName}
												color={color}
												text={member.userName}
											/>
										)
									})}
								</div>
							}
						/>
					</List.Item>
				)}
			/>
		)
	}

	const hideVideo = () => {
		setShowVideo(false)
	}

	const hideChat = () => {
		setShowVideo(true)
	}

	return (
		<>
			<Video
				show={showVideo}
				client={client}
				resolveMatrixId={resolveMatrixId}
				addRoom={addRoom}
				onCallStart={hideChat}
				onCallEnd={hideVideo}></Video>
			{!showVideo ? (
				<>
					<Sider collapsedWidth="0" width="320" collapsible theme="light" collapsed={!display} trigger={null}>
						<>
							<div className="exo-matrix-chat-rooms">{renderRooms()}</div>
						</>
					</Sider>
					<Sider collapsedWidth="0" width="400" collapsible theme="light" collapsed={!(openRoom && display)} trigger={null}>
						<Room room={openRoom} newMessageEvent={newMessageEvent} client={client} onClose={hideRoom} members={openRoom?.members}></Room>
					</Sider>
				</>
			) : null}
		</>
	)
}

const Room = function ({ onClose, room, client, members = [], newMessageEvent }) {
	const lang = React.useContext(LangContext)(translations)
	const utils = new Utils()

	const chunkSize = 10
	const [prevMessageAmount, setPrevMessageAmount] = useState(-1) // to figure out wether there is more data to load, should be equivalent with messages if there is more
	const [messages, setMessages] = useState([])
	const [newMessage, setNewMessage] = useState('')

	const parseMessages = ({ room, members }) => {
		const getUserName = (userId) => {
			return members.find((member) => member.userId === userId)?.userName
		}

		const getText = (event) => {
			if (event.type === types.MESSAGE) {
				return event?.content?.body
			} else if (event.type === types.CALL_INVITE) {
				return lang('message-type-call-invite')
			} else if (event.type === types.CALL_HANGUP) {
				return lang('message-type-call-hangup')
			} else if (event.type === types.CALL_ANSWER) {
				return lang('message-type-call-answer')
			}
		}

		return room?.timeline
			? room.timeline
					.filter(
						(item) =>
							item.event.type === types.MESSAGE ||
							item.event.type === types.CALL_INVITE ||
							item.event.type === types.CALL_HANGUP ||
							item.event.type === types.CALL_ANSWER
					)
					.map((item) => {
						return {
							userName: getUserName(item?.sender?.userId),
							type: item.event.type,
							key: `${item?.localTimestamp}`,
							txt: getText(item?.event),
							ts: utils.toDate({ timestamp: item.localTimestamp }),
							me: item.sender.userId === client.getUserId()
						}
					})
			: []
	}

	const updateMessages = (room) => {
		setPrevMessageAmount(messages.length)
		setMessages(parseMessages({ room, members }))
	}

	const loadHistoryData = (room) => {
		if (room) {
			client
				.scrollback(room._d, chunkSize)
				.then((_room) => {
					updateMessages(_room)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	useEffect(() => {
		if (client && room) {
			loadHistoryData(room)
			client.setRoomReadMarkers(room.roomId, null, newMessageEvent)
		}
	}, [room])

	useEffect(() => {
		if (room && newMessageEvent && newMessageEvent.getRoomId() === room.roomId) {
			loadHistoryData(room)
			client.setRoomReadMarkers(room.roomId, null, newMessageEvent)
		}
	}, [newMessageEvent])

	const onInputChange = ({ target: { value } }) => {
		setNewMessage(value)
	}

	const scrollToBottom = () => {
		if (scrollableDiv.current) {
			scrollableDiv.current.scrollTo({ top: scrollableDiv.current.scrollHeight, behavior: 'smooth' })
		}
	}

	const submit = () => {
		client.sendMessage(room.roomId, {
			body: newMessage,
			msgtype: types.TEXT
		})
		setNewMessage('')
		scrollToBottom()
	}

	const headerContent = room ? `${lang('chat-room-header')}: ${room.name}` : ''

	const scrollableDiv = useRef(null)

	return (
		<div className="exo-matrix-chat-history">
			<div className="exo-matrix-chat-history-header">
				{headerContent}
				<Button type="close" className="exo-matrix-chat-history-close" ghost onClick={onClose}></Button>
			</div>
			<div
				id="scrollableDiv"
				ref={scrollableDiv}
				style={{
					height: 'calc(100% - 110px)',
					overflow: 'auto',
					padding: '0 16px',
					borderTop: '1px solid rgba(140, 140, 140, 0.35)',
					display: 'flex',
					flexDirection: 'column-reverse'
				}}>
				<InfiniteScroll
					dataLength={messages.length}
					next={loadHistoryData}
					inverse={true}
					hasMore={messages.length !== prevMessageAmount}
					style={{ display: 'flex', flexDirection: 'column-reverse' }}
					// loader={<Skeleton paragraph={{ rows: 1 }} active />}
					endMessage={<Divider plain>{lang('end-of-history')}</Divider>}
					scrollableTarget="scrollableDiv">
					<List
						dataSource={messages}
						renderItem={(item) => (
							<List.Item key={item.id}>
								<List.Item.Meta
									title={item.txt}
									description={`${item.userName}, ${item.ts}`}
									className={`${item.type === types.MESSAGE ? 'text' : 'action'} ${item.me ? 'own-message' : 'others-message'}`}
								/>
							</List.Item>
						)}
					/>
				</InfiniteScroll>
			</div>
			<div className="exo-matrix-chat-history-footer">
				<Input.Group style={{ width: '100%' }}>
					<Input
						value={newMessage}
						onChange={onInputChange}
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								submit()
							}
						}}
					/>
					<div className="exo-matrix-chat-history-input-controls">
						<SendOutlined onClick={() => submit()} />
					</div>
				</Input.Group>
			</div>
		</div>
	)
}
