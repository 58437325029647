import React, { useState } from 'react'
import { Dropdown, Button as AButton, Descriptions, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'

import Backend from '../../../api/Backend'

import List from '../../../components/List'
import Drawer from '../../../components/Drawer'
import ContentItem from '../../../components/ContentItem'
import HspProviderSelect from '../../../components/HspProviderSelect'

import Utils from '../../../components/Utils'
import Symptom from '../../../components/Symptom'
import ContentTypeSelect from '../../../components/ContentTypeSelect'
import { AppointmentScheduler } from '../../AppointmentScheduler'

import { LangContext } from '../../../components/Translation'
import { useGlobalContext } from '../../../components/AppContext'

import translations from './translations'

import './index.css'

export default function SymptomAlarms({
	className = '',
	patientId,
	clinicId,
	key,
	patientPseudoName,
	interventionGroup,
	onInterventionTriggered = () => {},
	data
}) {
	const actions = {
		EMAIL_SEND: 'EMAIL_SEND',
		PHONE_CALL: `PHONE_CALL`,
		PERSONAL_CONTACT: `PERSONAL_CONTACT`,
		RECOMMEND_CONTENT: 'RECOMMEND_CONTENT',
		RECOMMEND_HSP: 'RECOMMEND_HSP'
	}

	const backend = new Backend()
	const utils = new Utils()
	const lang = React.useContext(LangContext)(translations)

	const { globals, isBetterCareProject } = useGlobalContext()


	const [action, setAction] = useState()
	const [content, setContent] = useState([])
	const [selectedHSPs, setSelectedHSPs] = useState()

	const handleActionsClick = e => {
		const action = e.key
		if (action === actions.EMAIL_SEND || action === actions.PERSONAL_CONTACT || action === actions.PHONE_CALL) {
			performAction(action)
		} else {
			setAction(action)
		}
	}


	console.log(data)

	const performAction = action => {
		const interventionForSymptomId = data[0] ? data[0].resource?.id : null
		const interventionForSymptomString = data[0] ? data[0].resource?.symptom : null
		if (action === actions.RECOMMEND_CONTENT && content && content.length > 0) {
			const body = JSON.stringify({
				data: content.map((item) => {
					return { contentId: item?.key, contentType: item?.type?.id }
				}),
				interventionForSymptomId,
				interventionForSymptomString
			})
			backend.patientContent({
				patientId,
				body,
				type: backend.type.CREATE,
				cb: () => {
					setContent([])
					const message = lang('intervention-triggered')
					toast.info(message, { toastId: message })
				}
			})
		} else if (action === actions.RECOMMEND_HSP) {
			const body = JSON.stringify({
				data: selectedHSPs,
				interventionForSymptomId,
				interventionForSymptomString
			})
			backend.healthProvider({
				patientId,
				body,
				type: backend.type.CREATE,
				cb: () => {
					setSelectedHSPs([])
					const message = lang('intervention-triggered')
					toast.info(message, { toastId: message })
				}
			})
		} else if (action === actions.EMAIL_SEND || action === actions.PERSONAL_CONTACT || action === actions.PHONE_CALL) {
			const body = JSON.stringify({
				type: action,
				interventionForSymptomId,
				interventionForSymptomString
			})
			backend.patientIntervention({
				patientId,
				body,
				type: backend.type.CREATE,
				cb: () => {
					const message = lang('intervention-triggered')
					toast.info(message, { toastId: message })
				}
			})
		}
		resetAction()
		onInterventionTriggered()
	}

	const resetAction = () => {
		setAction(undefined)
	}

	const handleContentChange = values => {
		setContent(values)
	}

	const handleHspAssignment = values => {
		setSelectedHSPs(values)
	}

	


	const actionMenu = (
		<Menu onClick={handleActionsClick}>
			{/*	<Menu.Item key={actions.SCHEDULE_APPOINTMENT}>{ lang(actions.SCHEDULE_APPOINTMENT.toLowerCase()) }</Menu.Item> */}
			<Menu.Item disabled={!isBetterCareProject() || (!isBetterCareProject() && !interventionGroup)} key={actions.RECOMMEND_CONTENT}>
				{lang(actions.RECOMMEND_CONTENT.toLowerCase())}
			</Menu.Item>
			<Menu.Item disabled={!isBetterCareProject() || (!isBetterCareProject() && !interventionGroup)} key={actions.RECOMMEND_HSP}>
				{lang(actions.RECOMMEND_HSP.toLowerCase())}
			</Menu.Item>
			<Menu.Item disabled={!isBetterCareProject() || (!isBetterCareProject() && !interventionGroup)} key={actions.EMAIL_SEND}>
				{lang(actions.EMAIL_SEND.toLowerCase())}
			</Menu.Item>
			<Menu.Item disabled={!isBetterCareProject() || (!isBetterCareProject() && !interventionGroup)} key={actions.PHONE_CALL}>
				{lang(actions.PHONE_CALL.toLowerCase())}
			</Menu.Item>
			<Menu.Item disabled={!isBetterCareProject() || (!isBetterCareProject() && !interventionGroup)} key={actions.PERSONAL_CONTACT}>
				{lang(actions.PERSONAL_CONTACT.toLowerCase())}
			</Menu.Item>
		</Menu>
	)

	const renderInterventions = symptoms => {
		const renderType = intervention => {
			if (intervention.type === 'CONTENT') {
				return (
					<React.Fragment key={intervention}>
						<Descriptions.Item>{lang('content')}</Descriptions.Item>
						<Descriptions.Item>
							<ContentItem value={[intervention]} />
						</Descriptions.Item>
						<Descriptions.Item>{utils.toDate({ dateString: intervention.createdAt })}</Descriptions.Item>
						<Descriptions.Item>{intervention.initiator}</Descriptions.Item>
					</React.Fragment>
				)
			} else if (intervention.type === 'HSP') {
				intervention.title = intervention.resource.hspTitle

				return (
					<React.Fragment key={intervention}>
						<Descriptions.Item>{lang('hsp')}</Descriptions.Item>
						<Descriptions.Item>
							<ContentItem value={[intervention]} />
						</Descriptions.Item>
						<Descriptions.Item>{utils.toDate({ dateString: intervention.createdAt })}</Descriptions.Item>
						<Descriptions.Item>{intervention.initiator}</Descriptions.Item>
					</React.Fragment>
				)
			} else {
				return (
					<React.Fragment key={intervention}>
						<Descriptions.Item span={2}>{lang(intervention.type?.toLowerCase())}</Descriptions.Item>
						<Descriptions.Item>{utils.toDate({ dateString: intervention.createdAt })}</Descriptions.Item>
						<Descriptions.Item>{intervention.initiator}</Descriptions.Item>
					</React.Fragment>
				)
			}
		}


		return (
			<div style={{ position: 'relative' }}>
				<div className="flex" style={{ lineHeight: `${lineHeight}px`, position: 'absolute', right: '5px', top: '-70px' }}>
					<Dropdown overlay={actionMenu} style={{ margin: `auto` }}>
						<AButton>
							{lang('trigger-intervention')} <DownOutlined />
						</AButton>
					</Dropdown>
				</div>
				<Descriptions title={lang('interventions')} column={{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 4, xs: 4 }}>
					{symptoms.length > 0 ? symptoms[0].interventions.map(renderType) : ''}
				</Descriptions>
			</div>
		)
	}
	const lineHeight = 75
	const symptoms = Array.isArray(data)
		? data.map(d => {
				return {
					title: <Symptom symptom={d.resource.symptom} severity={d.resource.severity} score={d.resource.score} />,
					content: <></>,
					description: <div>{utils.toDate({ dateString: d.created_at })}</div>,
					id: d.id
				}
		  })
		: []

	let actionElement = null
	if (action === actions.SCHEDULE_APPOINTMENT) {
		actionElement = <AppointmentScheduler className="mt20" data={{ patientId }} />
	} else if (action === actions.RECOMMEND_CONTENT) {
		actionElement = (
			<ContentTypeSelect
				key={content.length}
				placeholder={lang('select')}
				value={content}
				clinicId={clinicId}
				group={['ALL', 'INTERVENTION']}
				onChange={handleContentChange}
			/>
		)
	} else if (action === actions.RECOMMEND_HSP) {
		actionElement = <HspProviderSelect clinicId={globals.selectedCenter} placeholder={lang('select')} value={selectedHSPs} onChange={handleHspAssignment} />
	}


	return (
		<div className={`${className}`}>
			<div className="col-md-12 col-xl-12">
				<Drawer
					key={key}
					placement="right"
					width={'70vw'}
					onClose={() => {
						resetAction()
					}}
					onOK={() => {
						performAction(action)
					}}
					open={action}
					text={{
						title: `${lang('intervention')} ${lang('for')} ${patientPseudoName}`,
						cancel: lang('cancel'),
						ok: lang('ok')
					}}>
					{actionElement}
				</Drawer>
				<div className="exo-task-symptom-alarms">
					<List data={symptoms} pagination={null} />
				</div>
				<div className="exo-task-symptom-alarm-interventions">{renderInterventions(data)}</div>
			</div>
		</div>
	)
}
