import { useKeycloak } from '@react-keycloak/web'
import { UIConstants } from '../components/Utils'
import { Utils as FUtils } from '../components/Utils'
import { toast } from 'react-toastify'

import translations from './translations'

export default function Utils() {
	const { keycloak, initialized } = useKeycloak()

	const locale = FUtils.getLocale(keycloak)

	const lang = id => {
		return translations[locale][id]
	}

	return {
		call: async ({ cb = () => {}, cbError = () => {}, url, type, body, file = false }) => {
			// STRAPI headers should be without content-type or else it will fail to fetch
			const headers = file
				? {
						Accept: 'application/json, text/plain, */*',
						Authorization: initialized ? `Bearer ${keycloak.token}` : undefined
				  }
				: {
						Accept: 'application/json, text/plain, */*',
						'Content-Type': 'application/json',
						Authorization: initialized ? `Bearer ${keycloak.token}` : undefined
				  }

			try {
				const response = await fetch(`${url}`, {
					method: type,
					headers,
					body
				})
				let message = ''
				if (response.status === 204) {
					return cb()
				}
				let responseBody = ''
				try {
					responseBody = await response.json()
				} catch (e) {
					responseBody = ''
				}

				if (response.status === 401) {
					message = `${lang('401')}`
					//toast.error(message, { toastId: message })
					if (responseBody.invalidToken) keycloak.logout()
				}

				if (!response.ok) {
					//toast.error(responseBody.message, { toastId: responseBody.message })
					cbError({
						status: response.status,
						message: responseBody.message
					})
				} else {
					responseBody.data ? cb(responseBody.data) : cb(responseBody)
				}

				if (response.status === 500) {
					message = `${lang('500')} ${lang('on-it')}`
					return toast.error(message, { toastId: message })
				}
			} catch (error) {
				toast.error(error.message, { toastId: error.message })
				console.log(error.message)
			}
		},
		getFilter: params => {
			let filter = '?'
			for (const [key, value] of Object.entries(params)) {
				if (value !== UIConstants.ALL && value !== undefined) {
					filter = filter + `${filter === '?' ? '' : '&'}${key}=${Array.isArray(value) ? JSON.stringify(value) : value}`
				}
			}
			return filter === '?' ? '' : filter
		}
	}
}


