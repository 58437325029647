import React, { useState, useEffect, useContext } from 'react'
import { LangContext } from '../../components/Translation'
import ReactApexChart from 'react-apexcharts'
import Card from 'antd/lib/card/Card'
import { getHeartRateExtremumsDaily } from '../../api-new'
import translations from './translations'

const CandlestickChart = ({ title, data }) => {
	const options = {
		legend: {},
		chart: {
			type: 'candlestick',
			height: 350
		},
		title: {
			text: title,
			align: 'left'
		},
		xaxis: {
			type: 'datetime'
		},
		yaxis: {
			tooltip: {
				enabled: true
			}
		}
	}
	const series = [
		{
			data: data
		}
	]
	return (
		<div>
			<div id="chart">
				<ReactApexChart options={options} series={series} type="candlestick" height={350} />
			</div>
			<div id="html-dist"></div>
		</div>
	)
}

const IntegrationsOverview = ({ patientId }) => {
	const lang = useContext(LangContext)(translations)
	const [dailyHeartRate, setDailyHeartRate] = useState([])

	useEffect(() => {
		const refresh = async () => {
			try {
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				const response = await getHeartRateExtremumsDaily(patientId, '2024-04-01', '2024-04-30', timezone)
				var timezoneOffset = new Date().getTimezoneOffset() * 60000
				const data = response
					.map((element) => {
						return {
							x: new Date(Date.parse(`${element.date}`) + timezoneOffset),
							y: [element.minValue, element.minValue, element.maxValue, element.maxValue]
						}
					})
					.sort((first, second) => first.x > second.x)
				setDailyHeartRate(data)
			} catch (error) {
				console.log(error)
			}
		}
		refresh()
	}, [])

	return (
		<Card>
			<CandlestickChart data={dailyHeartRate} title={lang('heart-rate')} />
		</Card>
	)
}

export default IntegrationsOverview
