import axios from 'axios'

const keyHubUrl = process.env.REACT_APP_SECRET_SERVICE_URL
const healthHubUrl = process.env.REACT_APP_HEALTH_SERVICE_URL
const tenant = process.env.REACT_APP_TENANT
const client = process.env.REACT_APP_KC_CLIENT_ID

const authPath = `/v1/${tenant}/auth/provider/${client}/authorize`
export const refreshPath = `/v1/${tenant}/auth/provider/${client}/refresh`

// common

const makeRequest = async (url, method, body) => {
	var response
	if (method === 'POST' || method === 'post') {
		response = await axios.post(url, body)
	} else {
		response = await axios({ method: method, url: url })
	}
	const responseBody = response.data
	return responseBody
}

const callUnauthorizeEndpoint = async (baseUrl) => {
	try {
		await makeRequest(`${baseUrl}/v1/${tenant}/auth/logout`, 'POST')
	} catch (error) {
		console.error(error)
	}
}

const checkAuthentication = async (baseUrl, userId) => {
	const redirect = () => {
		window.location.replace(`${baseUrl}${authPath}`)
	}
	try {
		const response = await makeRequest(`${baseUrl}/v1/${tenant}/auth/me`, 'GET', null)
		if (userId !== response.id) {
			await callUnauthorizeEndpoint(baseUrl)
			redirect()
		}
	} catch (error) {
		console.error(error)
		if (error.response.status === 401) {
			redirect()
		}
		throw error
	}
}

export const refresh = async (baseUrl) => {
	return await makeRequest(`${baseUrl}${refreshPath}`, 'POST')
}

export const checkKeyHubAuthentication = async (userId) => {
	await checkAuthentication(keyHubUrl, userId)
}

export const checkHealthHubAuthentication = async (userId) => {
	await checkAuthentication(healthHubUrl, userId)
}

export const unauthorize = async () => {
	callUnauthorizeEndpoint(keyHubUrl)
	callUnauthorizeEndpoint(healthHubUrl)
}

// Health service
// Uploads

export const getUploadsSharedWithMe = async (id, page = 0) => {
	return await makeRequest(`${healthHubUrl}/v1/${tenant}/uploads/shared/by/${id}?` + new URLSearchParams({ page: page }), 'GET', null)
}

export const getUploadsSharedWith = async (id, page = 0) => {
	return await makeRequest(`${healthHubUrl}/v1/${tenant}/uploads/shared/with/${id}?` + new URLSearchParams({ page: page }), 'GET', null)
}

export const getUpload = async (id) => {
	return await makeRequest(`${healthHubUrl}/v1/${tenant}/uploads/${id}`, 'GET', null)
}

export const createUpload = async (keyId, data, filename, derivation, options, sharedWith, category) => {
	return await makeRequest(`${healthHubUrl}/v1/${tenant}/uploads`, 'POST', {
		keyId: keyId,
		data: data,
		filename: filename,
		derivation: derivation,
		options: options,
		sharedWith: sharedWith,
		category: category
	})
}

export const getAvailableCategories = async () => {
	return await makeRequest(`${healthHubUrl}/v1/${tenant}/uploads/categories/available`)
}

// Appointments

export const getPatientAppointments = async (patientId) => {
	return await makeRequest(`${healthHubUrl}/v1/${tenant}/users/${patientId}/appointments`)
}

// Forms

export const assignForm = async (patientId, formId) => {
	await makeRequest(`${healthHubUrl}/v1/${tenant}/users/${patientId}/forms/${formId}/assignments`, 'POST')
}

export const getLatestFormAnswers = async (patientId, formId) => {
	const result = await makeRequest(`${healthHubUrl}/v1/${tenant}/users/${patientId}/forms/${formId}/assignments/latest/answers`)
	return result
}

// Keys service

export const getKey = async (id) => {
	return await makeRequest(`${keyHubUrl}/v1/${tenant}/keys/${id}`, 'GET', null)
}

export const createKey = async (sharedWith) => {
	return await makeRequest(`${keyHubUrl}/v1/${tenant}/keys`, 'POST', { sharedWith: sharedWith })
}

// Heart rate

export const getHeartRateExtremumsDaily = async (patientId, startDate, endDate, timezone) => {
	return await makeRequest(
		`${healthHubUrl}/v1/${tenant}/users/${patientId}/heart-rate-extremums-daily?` +
			new URLSearchParams({ startDate: startDate, endDate: endDate, timezone: timezone }),
		'GET',
		null
	)
}
