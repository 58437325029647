import React, { useState } from 'react'

import { toast } from 'react-toastify'

import { getKey, getUpload, getUploadsSharedWithMe, getUploadsSharedWith } from '../../api-new'
import { decryptMessage } from '../../encryption'

import Button from '../../components/Button'
import { LangContext } from '../../components/Translation'
import translations from './translations'
import TableWithPagination from '../../components/TableWithPagination'
import { Segmented } from 'antd'

const PatientUploads = ({ patientId }) => {
	const lang = React.useContext(LangContext)(translations)

	const tabContexts = {
		WITH_USER: lang('tabs-shared-with-user'),
		WITH_PATIENT: lang('tabs-shared-with-patient')
	}
	const transformedTabContext = Object.values(tabContexts)

	const [tabContext, setTabContext] = useState(tabContexts.WITH_USER)

	const downloadFile = async (id) => {
		try {
			const fileData = await getUpload(id)
			const keyData = await getKey(fileData.keyId)
			const decryptedData = await decryptMessage(keyData.key, fileData.data, fileData.deriavtion, fileData.options)

			const blob = new Blob([decryptedData], { type: 'octet/stream' })
			const url = URL.createObjectURL(blob)
			const link = document.createElement('a')
			link.href = url
			link.download = fileData.filename
			document.body.appendChild(link)
			link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
			document.body.removeChild(link)
		} catch (error) {
			console.log(error)
			toast.error('Could not download file')
		}
	}

	const dataSource = (page) => {
		switch (tabContext) {
			case tabContexts.WITH_PATIENT:
				return getUploadsSharedWith(patientId, page)
			case tabContexts.WITH_USER:
				return getUploadsSharedWithMe(patientId, page)
		}
	}

	const tabContextSwitcher = () => {
		return (
			<div>
				<Segmented options={transformedTabContext} value={tabContext} onChange={setTabContext} />
			</div>
		)
	}

	return (
		<>
			<TableWithPagination
			key={tabContext}
				title={tabContextSwitcher()}
				columns={[
					{ dataIndex: 'filename', title: lang('filename') },
					{
						title: lang('actions'),
						width: 120,
						fixed: 'right',
						render: (text, record) => (
							<Button
								onClick={() => {
									downloadFile(record.id)
								}}
								type="open"
							/>
						)
					}
				]}
				fetchData={({ page }) => dataSource(page)}
			/>
		</>
	)
}

export default PatientUploads
